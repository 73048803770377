import React, {useRef, useState} from "react";
import {useQuery} from "@apollo/client";
import {Stack} from "@mui/material";

import {Gantt, WbsItemForm, WbsList} from "../containers/WebApp/components";
import projectsInfo from "../containers/WebApp/components/Charts/logic";
import WebAppLayout from "../containers/WebApp/WebAppLayout";
import {projectsVar} from "../gatsby-plugin-apollo/cache";
import {GET_PROJECTS} from "../gatsby-plugin-apollo/queries";

const Program = ({projectIndex, name}) => {
    const projects = projectsVar();
    const {data, loading, error} = useQuery(GET_PROJECTS, {
        variables: {
            getProyectosId: "63038693768ee8e7b37a1b48",
        },
    });
    const {intervalDatesInfo, timeSpan} = projectsInfo(projects);

    //MANEJADORES DE LA SELECCION DEL ITEM
    const [selectedItem, setSelectedItem] = useState(0);
    const handleItemSelect = (itemIndex) => {
        setSelectedItem(itemIndex);
        handleOpenFormDisplay();
    };
    const [hoveredItem, setItemHover] = useState(0);
    const handleItemHover = (itemIndex) => {
        setItemHover(itemIndex);
    };
    const [hoveredTime, setTimeHover] = useState("");
    const handleTimeHover = (timeIndex) => {
        setTimeHover(timeIndex);
    };

    //MANEJADORES DEL FORMULARIO
    const [formDisplay, setFormDispay] = useState(false);
    const handleOpenFormDisplay = () => {
        setFormDispay(true);
    };
    const handleCloseFormDisplay = () => {
        setFormDispay(false);
    };

    // MANEJADORES DE LA SINCRONIZACIÓN DE SCROLLS:
    const itemRef = useRef();
    const ganttRef = useRef();
    const ganttSpanRef = useRef();
    const chartRef = useRef();
    const handleItemScroll = (scroll) => {
        ganttRef.current.scrollTop = scroll.target.scrollTop;
    };
    const handleGanttScroll = (scroll) => {
        itemRef.current.scrollTop = scroll.target.scrollTop;
    };
    const handleSpanScroll = (scroll) => {
        chartRef.current.scrollLeft = scroll.target.scrollLeft;
    };
    const handleChartScroll = (scroll) => {
        ganttSpanRef.current.scrollLeft = scroll.target.scrollLeft;
    };

    return (
        <WebAppLayout>
            <Stack
                backgroundColor={"secondary.dark"}
                direction={"row"}
                flexGrow={1}
                height={"100%"}
                justifyContent={"space-between"}
                p={2}
                spacing={2}
            >
                <WbsList
                    data={projects[projectIndex]}
                    hover={{hoveredItem, handleItemHover}}
                    scroll={{itemRef, handleItemScroll}}
                    select={{selectedItem, handleItemSelect}}
                />
                <Stack height={"100%"} overflow={"hidden"} width={"100%"}>
                    {formDisplay ? (
                        <WbsItemForm
                            close={handleCloseFormDisplay}
                            data={projects[projectIndex]}
                            open={handleOpenFormDisplay}
                        />
                    ) : (
                        <Gantt
                            data={projects}
                            hover={{
                                hoveredProject: hoveredItem,
                                handleItemHover,
                                hoveredTime,
                                handleTimeHover,
                            }}
                            scroll={{ganttRef, ganttSpanRef, handleGanttScroll, handleSpanScroll}}
                            select={{
                                selectedProject: selectedItem,
                                handleItemSelect,
                            }}
                            status={(error, loading)}
                            time={{intervalDatesInfo, timeSpan}}
                        />
                    )}
                </Stack>
            </Stack>
        </WebAppLayout>
    );
};

export default Program;
